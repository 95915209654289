import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	Flex,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as Icon from "react-icons/bi";
import * as Feather from "react-icons/fi";

const getData = graphql`
	{
		file(name: { eq: "clare-headshot" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 400
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		logo: file(name: { eq: "Kingborough-Thrives-stack" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 220
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
	}
`;

const Kingborough = () => {
	const data = useStaticQuery(getData);
	const clare = getImage(data.file);
	const logo = getImage(data.logo);
	return (
		<Layout>
			<HeadSEO
				title="Our Values | Kingborough Thrives"
				description="Kingborough Thrives is a working group of engaged local residents who are seeking to increase active involvement in local democracy."
				slug="/kingborough-thrives/"
			/>
			<Box bg="gray.50" py="2rem" overflow="hidden">
				<Flex mx="auto" w="87.5vw" maxW="80rem" justify="center">
					<Box
						as={Link}
						to="/"
						alignSelf="center"
						maxH="100%"
						mr={["1rem", "1rem", "2rem"]}
					>
						<GatsbyImage image={logo} alt="Kingborough Thrives logo" />
					</Box>
					<VStack
						pl="2rem"
						align="flex-start"
						as="nav"
						alignSelf="center"
						spacing="1rem"
						// display={["none", "none", "flex"]}
					>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/"
							_hover={{ bg: "blue.800" }}
						>
							Home
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/about/"
							_hover={{ bg: "blue.800" }}
						>
							About Clare
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.700"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/kingborough-thrives/"
							_hover={{ bg: "blue.800" }}
						>
							Kingborough Thrives
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/contact/"
							_hover={{ bg: "blue.800" }}
						>
							Get in Touch
						</Heading>
					</VStack>
				</Flex>
			</Box>
			<Box bg="blue.600" color="white">
				<Box mx="auto" w="87.5vw" maxW="64rem" py="4rem">
					<Heading
						as="h1"
						size="md"
						color="yellow.400"
						textTransform="uppercase"
					>
						Kingborough Thrives
					</Heading>
					<Heading>Your local network of Independents for Kingborough</Heading>
				</Box>
			</Box>
			<Box id="aboutclare" mx="auto" w="87.5vw" maxW="48rem" py="4rem">
				<VStack spacing="2rem" align="flex-start">
					<Heading>Who We Are</Heading>
					<Text>
						Kingborough Thrives is a working group of engaged local residents
						who are seeking to increase active involvement in local democracy.
						Some of our members will stand in the Kingborough local government
						elections in 2022 as a network of independent candidates.
					</Text>
					<Heading>Our Values:</Heading>
					<Text>
						Our focus is to lead with empathy and kindness, to ensure the
						people, creatures, and environment of Kingborough are listened to,
						cared for, and respected.
					</Text>

					<Grid
						gridTemplateColumns={["1fr", "1fr", "50% 50%"]}
						w="100%"
						gap="2rem"
					>
						<Box>
							<Heading as="h3" size="md" mb="1.5rem">
								How we agree to be is:
							</Heading>
							<ListOne />
						</Box>
						<Box>
							<Heading as="h3" size="md" mb="1.5rem">
								What we agree to do is:
							</Heading>
							<ListTwo />
						</Box>
					</Grid>

					<Heading>Our Vision for a Thriving Kingborough</Heading>
					<Text>
						A thriving future begins with reconciliation with First Nations
						people being celebrated at the very heart of what it means to be
						Tasmanian. We acknowledge healing of our past is essential and must
						be an ongoing process to move forward.
					</Text>
					<Heading as="h3" size="lg">
						Thriving People
					</Heading>
					<Text>
						We acknowledge that for the people of Kingborough to thrive we must
						respect all our differences. The key to a thriving community is:
					</Text>
					<ListThree />
					<Heading as="h3" size="lg">
						Thriving Economy
					</Heading>
					<Text>
						We value a thriving economy that serves people and the planet in a
						fair and just way. This includes:
					</Text>
					<ListFour />
					<Heading as="h3" size="lg">
						Thriving Environment
					</Heading>
					<Text>
						We value the protection and regeneration of Kingborough’s local
						ecology:
					</Text>
					<ListFive />
				</VStack>
			</Box>
		</Layout>
	);
};

export default Kingborough;

const ListOne = () => {
	return (
		<List spacing="1.5rem">
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Loving
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Kind
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Honest
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Courageous
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Accountable
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Respectful
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Transparent
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Compassionate
			</ListItem>
		</List>
	);
};

const ListTwo = () => {
	return (
		<List spacing="1.5rem">
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Act urgently
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Listen deeply
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Seek to understand
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Collaborate and unify
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Lead from a place of love
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Lead by example
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Act with integrity
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Communicate simply
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Straight talk
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Be assertive
			</ListItem>
		</List>
	);
};

const ListThree = () => {
	return (
		<List spacing="1.5rem">
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Bringing people together
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Equality and kindness for all
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Celebrating and elevating women
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Diversity and dignity
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Individual and collective freedom and responsibility
			</ListItem>
		</List>
	);
};

const ListFour = () => {
	return (
		<List spacing="1.5rem">
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Meeting basic needs: safe shelter, clean air & water, good food
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Efficient services across the community
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Vibrant local business and work
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Circular economy
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Social enterprise & innovations
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				High quality work, rest and play for all
			</ListItem>
		</List>
	);
};

const ListFive = () => {
	return (
		<List spacing="1.5rem">
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				By acknowledging that urgently addressing climate chaos and ecological
				sustainability is key to our future
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				By acknowledging that care for the envrionment and good business
				practice are complementary, not mutually exclusive
			</ListItem>
			<ListItem>
				<ListIcon
					color="blue.500"
					position="relative"
					bottom="0.1rem"
					as={Feather.FiChevronRight}
				/>
				Adopting sustainable, evidence-based, environmental wisdom
			</ListItem>
		</List>
	);
};
